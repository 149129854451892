<template>
    <div class="action-schedule">
        <div class="message">
            <div class="message-top">
                <img :src="require('@/assets/images/icon-hand.png')" alt="" />
                <span v-html="introduction.title" />
            </div>
            <div class="message-content">
                <!-- <div class="desc" v-html="introduction.desc" /> -->
                <div class="desc" v-for="(d, idx) in desc" :key="idx" :class="{ 'li-dot': desc.length > 1 }">
                    {{ d }}
                </div>
                <div class="grey-box m-t-8">
                    <div class="user-profile">
                        <img :src="photoUrl" alt="" />
                        <div class="profile-info">
                            <div class="age" v-html="age" />
                            <div class="region" v-html="content.region" />
                            <div class="job" v-html="content.job" />
                        </div>
                    </div>
                </div>
                <div class="grey-box m-t-8" v-if="isAtype">
                    <ScheduleProgress :mode="isAtype ? 'a' : 'b'" :selected="2" />
                </div>
            </div>
        </div>
        <!--        <BottomButton :disabled="disabled" @click="schedulePay" v-if="!isAtype" :non-fixed="true" :label="`결제하기`" />-->
    </div>
</template>

<script>
import ScheduleProgress from './ScheduleProgress'

export default {
    name: 'ActionScheduleFinishA',
    props: ['message'],
    components: {
        ScheduleProgress,
    },
    methods: {
        schedulePay() {
            this.$stackRouter.push({
                name: 'SchedulePayPage',
                props: {
                    userInfo: {
                        ...this.content,
                        photoUrl: this.photoUrl,
                        age: this.age,
                        region: this.content.region,
                        job: this.content.job,
                        message: this.message,
                    },
                },
            })
        },
    },
    computed: {
        isAtype() {
            // return this.message.mtype === 'schedule-finish-a'
            return this.$store.getters.me.gender === 1
        },
        disabled() {
            return this.content.status === 1
        },
        introduction() {
            return {
                title: this.isAtype ? '잠시만 기다려주세요!' : '잠시만 기다려주세요!',
                desc: this.isAtype
                    ? '남성분에게 가능한 일정이 있는지 확인중입니다. 남성분 확인까지 시간이 소요될 수 있습니다.<br>하루가 지나도 남성분 일정을 못 받았다면, 매니저에게 이야기해주세요.'
                    : '여성분에게 가능한 일정이 있는지 확인중이에요. 여성분의 일정 선택이 완료되면 안내드릴게요',
            }
        },
        desc() {
            if (this.isAtype) {
                return [
                    '남성분에게 가능한 일정이 있는지 확인중입니다. 남성분 확인까지 시간이 소요될 수 있습니다.',
                    '하루가 지나도 남성분 일정을 못 받았다면, 매니저에게 이야기해주세요.',
                ]
            } else {
                return ['여성분에게 가능한 일정이 있는지 확인중이에요. 여성분의 일정 선택이 완료되면 안내드릴게요']
            }
        },
        content() {
            return this.$mustParse(this.message.content)
        },
        photoUrl() {
            return this.content.urls[0] || null
        },
        age() {
            return `${this.$options.filters.asAge(this.content.birthday)}세`
        },
    },
}
</script>
<style scoped lang="scss">
.desc {
    font-size: 14px;
    &.li-dot {
        display: list-item;
        align-items: flex-start;
        margin-left: 16px;
    }
}
</style>
